import React from 'react'
import {  graphql, Link } from 'gatsby'
// import { css } from "react-emotion"
import Layout from '../components/layout'


export default ({ data }) => (
  <Layout>
    <div className="mx-6">
      <h1 className="mt-6 mb-4 text-color">Blog </h1>

      <div className="home-project-grid mx-0 pb-8">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} className="my-4">
            <h2>
              <Link className="text-black text-2xl text-color" to={node.fields.slug}>{node.frontmatter.title} </Link>
            </h2>
            <small className="text-black text-sm my-2 block text-color">{node.frontmatter.date}</small>
            <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          </div>
        ))}
      </div>
    </div>
  </Layout>
)


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        tagLine
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date]},
      filter: {fileAbsolutePath: {regex: "/(\\/articles)/.*.md$/"}}
    ){
      totalCount
      edges {
        node {
          tableOfContents(pathToSlugField: "frontmatter.path")
          fields {
            slug
          }
          wordCount {
            paragraphs
            sentences
            words
          }
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            cover
          }
          excerpt
          html
          headings {
            depth
            value
          }
        }
      }
    }
  }`